import { AfterSuccess } from '@cosmos/state';
import type { SearchCriteria } from '@esp/common/types';
import type {
  CompanyDecoration,
  CompanyDecorationSearch,
} from '@esp/orders/types';

const ACTION_SCOPE = '[Companies Decorations]';

export namespace CompaniesDecorationsActions {
  export class SearchWithExistingCriteria {
    static type = `${ACTION_SCOPE} Search With Existing Criteria`;
  }

  @AfterSuccess(CompaniesDecorationsActions.SearchWithExistingCriteria)
  export class Activate {
    static readonly type = `${ACTION_SCOPE} Activate`;

    constructor(public decoration: CompanyDecorationSearch) {}
  }

  @AfterSuccess(CompaniesDecorationsActions.SearchWithExistingCriteria)
  export class Archive {
    static readonly type = `${ACTION_SCOPE} Archive`;

    constructor(public decoration: CompanyDecorationSearch) {}
  }

  @AfterSuccess(CompaniesDecorationsActions.SearchWithExistingCriteria)
  export class Create {
    static readonly type = `${ACTION_SCOPE} Create`;

    constructor(public decoration: CompanyDecoration) {}
  }

  @AfterSuccess(CompaniesDecorationsActions.SearchWithExistingCriteria)
  export class Delete {
    static readonly type = `${ACTION_SCOPE} Delete`;

    constructor(public decoration: CompanyDecorationSearch) {}
  }

  export class Get {
    static readonly type = `${ACTION_SCOPE} Get`;

    constructor(
      public companyId: number,
      public decorationId: number
    ) {}
  }

  @AfterSuccess(CompaniesDecorationsActions.SearchWithExistingCriteria)
  export class Patch {
    static readonly type = `${ACTION_SCOPE} Update`;

    constructor(public decoration: CompanyDecoration) {}
  }

  export class PrepareCriteria {
    static readonly type = `${ACTION_SCOPE} Prepare Criteria`;

    constructor(public criteria: SearchCriteria) {}
  }

  export class ResetCurrentDecoration {
    static readonly type = `${ACTION_SCOPE} Reset Current Decoration`;
  }

  export class Search {
    static type = `${ACTION_SCOPE} Search`;

    constructor(public criteria: SearchCriteria) {}
  }
}
