import { CommonModule } from '@angular/common';
import { NgModule, type ModuleWithProviders } from '@angular/core';
import { NgxsModule } from '@ngxs/store';

import { NgxsAfterSuccessModule } from '@cosmos/state';

import { NotesService } from './services';
import { NotesSearchState } from './states';

@NgModule()
export class EspNotesModule {
  static forRoot(): ModuleWithProviders<RootEspNotesModule> {
    return { ngModule: RootEspNotesModule };
  }
}

@NgModule({
  imports: [
    CommonModule,
    NgxsModule.forFeature([NotesSearchState]),
    NgxsAfterSuccessModule,
  ],
  providers: [NotesService],
})
export class RootEspNotesModule {}
